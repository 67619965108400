<template>
  <transition name="fade" appear>
    <div class="home">
      <el-row>
        <!-- <el-col :span="6">
          <div class="choice">
            <div class="choice_title">选择章节</div>
            <div class="list">
              <template v-for="(item, index) in List">
                <div
                  :class="[index == typeIndex ? 'type_item_true' : 'type_item_false']"
                  :key="index"
                  @click="change(index)"
                >
                  {{ item.chapterName }}
                </div>
              </template>
              <el-backtop target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>
            </div>
          </div>
        </el-col> -->
        <el-col :span="18">
          <video :src="chapter.url" controls class="div_video" v-if="chapter.urlType == '0'" controlsList="nodownload" oncontextmenu="return false" autoplay/>
          <iframe class="div_video" :src="chapter.url" frameborder="0" v-else-if="chapter.urlType == '1'">
          </iframe>
          <iframe
            class="div_video"
            :src="'http://view.officeapps.live.com/op/view.aspx?src=' + chapter.url"
            frameborder="0"
            v-else
          >
          </iframe> 
           <div style="font-size:20pt;margin-left:-60%;margin-top:2%;">课程</div>
          <div class="title">{{ course.courseName }}</div>
          <div style="margin-left:-60%;margin-top:2%;font-size:18pt;">章节{{ chapter.chapterNo }}</div>
          <div class="title">{{ course.chapterName }}</div>
          <!-- <el-row class="content">
            <!-- 点赞 -->
            <!-- <el-col :span="8">
              <div class="el_left">
                <div class="action_groupItem flex" @click="setFavorite(isUp)">
                  <div v-if="!isUp">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-dianzan"></use>
                    </svg>
                  </div>
                  <div v-else>
                    <svg class="icon" aria-hidden="true" style="color: #db3b3d">
                      <use xlink:href="#icon-dianzan_active"></use>
                    </svg>
                  </div>
                  <div class="action_text">{{ LikeCount }}</div>
                </div>
              </div>
            </el-col>
            <!-- 收藏 -->
            <!-- <el-col :span="8">
              <div class="el_center">
                <div class="action_groupItem flex" @click="setCollect(isCollent)">
                  <div v-if="!isCollent">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-shoucang"></use>
                    </svg>
                  </div>
                  <div v-else>
                    <svg class="icon" aria-hidden="true" style="color: #db3b3d">
                      <use xlink:href="#icon-shoucang_active"></use>
                    </svg>
                  </div>
                  <div class="action_text">{{ CollectCount }}</div>
                </div>
              </div>
            </el-col> -->
            <!-- 转发 -->
            <!-- <el-col :span="8">
              <div class="el_right">
                <div class="action_groupItem flex" @click="copy">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-fenxiang"></use>
                  </svg>
                </div>
              </div> 
            </el-col>  -->
          <!-- </el-row> -->
        </el-col>
      </el-row>
    </div>
  </transition>
</template>

<script>
// import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  name: "curriculumCollect",
  data() {
    return {
      title: "公共管理学课程教学大纲",
      lessonUrl: "",
      //点赞
      isUp: false,
      //收藏
      isCollent: false,
      typeIndex: 0,
      List: [],
      courseCode: "",
      LikeTemporary: {},
      LikeCount: "",
      CollectTemporary: {},
      CollectCount: "",
      userInfo: {},
      logo:"1",
      course:{},
       chapter: {},
    };
  },
  methods: {
   
    //课程详情
    getCourseDetail() {
      var getData = {};
      var condition = {};
      condition.courseCode = this.courseCode;
      getData.condition = condition;
      this.$api.lesson.getCourseDetail(getData).then((res) => {
        if (res.data.code === 200) {
          this.List = res.data.data.data[0].courseChapterList;
          this.title = res.data.data.data[0].courseName;
          this.lessonUrl = this.List[0].url;
          this.getFavoriteCount();
          this.getCollectCount();
        }
      });
    },
    
 querryChapter () {
      // debugger
       var getData = {};
     getData.chapterCode = this.course.chapterCode
      this.$api.lesson
        .getChapterDetail( getData)
        .then(response => {
          if (response.data.code == 200) {
            this.chapter = response.data.data
            // console.log('response', response.data.data)
          }
        });
    }
    
  },
  created() {
    
 
    this.course = JSON.parse(this.$route.query.course) 
    console.log( this.course )
    this.logo = this.$route.query.logo;
    this.getCourseDetail();
     this.querryChapter ()
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 1140px;
  height: 100%;
  margin-top: 40px;
  background-color: white;
  .div_video {
    width: 100%;
    height: 600px;
    margin-left: 17%;
    margin-top: -5%;
  }
  .title {
    font-size: 22px;
    margin-top: 15px;
    margin-left:17%;
    text-align: left;
  }
  .content {
    margin: 15px 0 0 20px;
  }
  .el_left {
    text-align: left;
  }
  .el_center {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .el_right {
    display: flex;
    justify-content: right;
    text-align: right;
  }
  .action_groupItem {
    display: flex;
    align-items: center;
  }
  .icon {
    width: 30px;
    height: 30px;
  }
  .icon:hover {
    cursor: pointer;
  }
  .action_text {
    font-size: 22px;
    margin-left: 5px;
  }
  .choice {
    margin: 0 30px 0 0;
    height: 600px;
    .choice_title {
      padding: 11px 0 11px 21px;
      font-size: 25px;
      background-color: #f6f7f6;
      text-align: left;
    }
    .list {
      max-height: 100%;
      overflow: auto;
    }
  }
  .type_item_true {
    padding: 11px 0 11px 21px;
    font-size: 18px;
    color: #fca823;
    border-bottom: 1px dotted #dddddd;
    text-align: left;
  }
  .type_item_true:hover{
    cursor: pointer;
  }
  .type_item_false {
    padding: 11px 0 11px 21px;
    font-size: 18px;
    color: #1e1e1e;
    border-bottom: 1px dotted #dddddd;
    text-align: left;
  }
   .type_item_false:hover{
    cursor: pointer;
  }
}
</style>
